@tailwind base;
@tailwind components;
@tailwind utilities;

/* @bitnoi.se/react-scheduler' this class has absolute as defaullt. */ 
.drnpen{
    position: relative !important;  
}

body {
    overflow: hidden !important;
}


[class*="col-"] {
    float: left !important;
    padding: 15px !important;
}

/* For desktop: */
.col-1 { width: 8.33% !important; }
.col-2 { width: 16.66% !important; }
.col-3 { width: 25% !important; }
.col-4 { width: 33.33% !important; }
.col-5 { width: 41.66% !important; }
.col-6 { width: 50% !important; }
.col-7 { width: 58.33% !important; }
.col-8 { width: 66.66% !important; }
.col-9 { width: 75% !important; }
.col-10 { width: 83.33% !important; }
.col-11 { width: 91.66% !important; }
.col-12 { width: 100% !important; }

.tabbar {
  position: fixed;
  bottom: 10;
  left: 0;
  width: 100%;
  padding: 35px env(safe-area-inset-bottom) 55px; /* Ruimte voor safe area */
  background-color: white; /* Zorg ervoor dat de tabbar een contrasterende kleur heeft */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #e5e5e5;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100% !important;
  }
}
.rsw-editor {
  border: none !important
  
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-semibold {
  font-weight: 600;
}
.text-slate-500 {
  color: #6b7280;
}
.uppercase {
  text-transform: uppercase;
}
.tracking-wide {
  letter-spacing: 0.05em;
}

